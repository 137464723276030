<template>
  <v-container fluid grid-list-lg>
    <Create :createDialog="createDialog" @close="createDialog = false" @submit="getList"></Create>
    <Edit :editDialog="editDialog" @close="editDialog = false" @submit="getList" :editTechLocation="selectedTechLocation"></Edit>
    <v-card :loading="loading" outlined>
      <ListTitle :showBackButton="true" title="Tech Location" @add="add"
        :color="$style.purchase.titlebar" />

      <v-card-title>
        <v-flex xs12 sm4>
          <v-text-field v-model="search" outlined prepend-inner-icon="search" rounded dense label="Search" autofocus
            single-line hide-details clearable></v-text-field>
        </v-flex>
      </v-card-title>
      <v-card-text>
        <v-data-table :headers="headers" :items="techLocations" :search="search" :loading="loading" :sort-by="sortBy"
          :page.sync="page" :items-per-page="itemsPerPage" @current-items="updateBalance">
          <template v-slot:item="{ item }">
            <tr>
              <td class="text-left" v-html="$highlight(item.name, search.toLowerCase())
                ">
                {{ item.name }}
              </td>
              <td class="text-left" v-html="$highlight(
                item.phoneNumber,
                search.toLowerCase()
              )
                ">
                {{ item.phoneNumber }}
              </td>
              <td class="text-left" v-html="$highlight(item.email, search.toLowerCase())
                ">
                {{ item.email }}
              </td>
              <td class="text-right">
                {{ item.balance | currency }}
              </td>
              <td class="justify-end align-center text-right">
                <v-menu bottom left offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon>
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>
                  <v-list class="menu-button">
                    <v-list-item @click="edit(item)">
                      <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="deleteTechLocation(item)">
                      <v-list-item-title>Delete</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
          <template v-slot:body.append="{ headers }" v-if="techLocations.length > 0">
            <tr :style="{ 'background-color': $style.listTotal.filter }">
              <td :colspan="headers.length - 2" class="text-right font-weight-bold">
                List Total
              </td>
              <td class="text-right font-weight-bold">
                {{ balance | currency }}
              </td>
              <td></td>
            </tr>
            <tr :style="{ 'background-color': $style.listTotal.result }">
              <td :colspan="headers.length - 2" class="text-right font-weight-bold">
                Result Total
              </td>
              <td class="text-right font-weight-bold">
                {{ fullBalance | currency }}
              </td>
              <td></td>
            </tr>
          </template>
          <v-alert slot="no-results" :value="true" color="error" icon="warning">Your search for "{{ search }}" found no
            results.</v-alert>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import techLocationService from "../service";
import { mapGetters } from "vuex";

const Create = () => import("./Create");
const Edit = () => import("./Edit");
export default {
  name: "techLocation-list",
  data() {
    return {
      createDialog: false,
      editDialog: false,
      selectedTechLocation: null,
      balance: 0,
      fullBalance: 0,
      headers: [
        {
          text: this.$t("labels.name"),
          align: "left",
          value: "name",
          sortable: true,
        },
        {
          text: "Phone Number",
          align: "left",
          value: "phoneNumber",
          sortable: true,
        },
        {
          text: "Email Address",
          value: "email",
          sortable: true,
        },
        {
          text: "Balance",
          value: "balance",
          sortable: true,
        },
        {
          text: "",
          value: "action",
          sortable: false,
        },
      ],
      techLocations: [],
      search: "",
      loading: true,
      page: 1,
      sortBy: "name",
      pageCount: 0,
      itemsPerPage: 10,
    };
  },
  created() {
    this.getList();
    document.title = "smplsale - TechLocation List";
  },
  computed: {
    ...mapGetters("global", ["currentUser", "checkRightStatus"]),
  },
  watch: {
    search(val) {
      if (!val) {
        this.search = "";
      }
    },
  },
  components: {
    Create,
    Edit,
  },
  methods: {
    add() {
      this.createDialog = !this.createDialog;
    },
    updateBalance(val){
      this.balance = 0;
      val.map((row) => {
        this.balance += row.balance;
      });
    },
    getList() {
      this.loading = true;
      this.editDialog = false;
      this.createDialog = false;
      return techLocationService.getAll().then((response) => {
        this.loading = false;
        this.techLocations = response.data;
        this.fullBalance = 0;
        this.techLocations.map((row) => {
          this.fullBalance += row.balance;
        });
        return response;
      });
    },
    edit(techLocation) {
      this.selectedTechLocation = techLocation;
      this.editDialog = !this.editDialog;
    },
    deleteTechLocation(techLocation) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "No",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          techLocationService.delete(techLocation.id).then((response) => {
            if (response.data) {
              this.$swal("Deleted!", "techLocation has been deleted.", "success");
              this.getList();
            }
          });
        }
      });
    },
  },
};
</script>
